import yooo from '../../assets/yooo.mp3'

function broadcastPulls(pulls, sessionId) {
    console.log('sessionId =', sessionId);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pulls })
    }
    fetch(process.env.REACT_APP_INDEV ? `http://${window.location.hostname}:8080/${sessionId}` : `http://guobapityapi.maufirf.com/${sessionId}`, requestOptions)
        .then(response => response.json())
        .then(data => {console.log(data)})
}

var newPulls;

function ControlPanel(props) {
    return(
        <>
            <h1>
                Wish Control
            </h1>
            <div className="buttonList">
                <button className="btn" onClick={()=>{
                    newPulls = [...props.pulls, 'rare'];
                    props.setPulls(newPulls);
                    broadcastPulls(newPulls, props.sessionId);
                }}>🌟🌟🌟</button>
                <button className="btn" onClick={()=>{
                    newPulls = [...props.pulls, 'epic'];
                    props.setPulls(newPulls);
                    broadcastPulls(newPulls, props.sessionId);
                }}>🌟🌟🌟🌟</button>
                <button className="btn" onClick={()=>{
                    newPulls = [...props.pulls, 'legendary'];
                    props.setPulls(newPulls);
                    broadcastPulls(newPulls, props.sessionId);
                    new Audio(yooo).play();
                }}>🌟🌟🌟🌟🌟</button>
                <button className="btn" onClick={()=>{
                    newPulls = props.pulls.slice(0,props.pulls.length-1);
                    props.setPulls(newPulls);
                    broadcastPulls(newPulls, props.sessionId);
                }}>remove</button>
            </div>
        </>
    )
}

export default ControlPanel