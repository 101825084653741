import { useState, useEffect } from 'react';
import './rarityBoxes.css'

const boxSize = 315;
const baseLegendary = 0.006;
const baseEpic = 0.051;
const capLegendary = 90;
const capEpic = 10;

function extractOdds(pulls, { setBoxLegendarySize, setBoxEpicSize, setBoxRareSize }) {
    // Get last indices for legendaryOdds and epicOdds
    // use Math.max() to make sure at least it starts at index 0
    const legendaryLastIndex = pulls.lastIndexOf('legendary')+1
    const epicLastIndex = pulls.lastIndexOf('epic')+1
    // Get number of pulls in pool for legendaryOdds and epicOdds
    // use Math.min() to make sure at most it is 10th pull for epic and 90th pull for legendary
    const pulledSinceLegendary = Math.max(Math.min(pulls.length-legendaryLastIndex,capLegendary),0)
    const pulledSinceEpic = Math.max(Math.min(pulls.length-epicLastIndex,capEpic),0)
    // Legendary can overwhelm group Epic+Rare
    // Epic can overhelm Rare
    // Thus, Legendary has to be counted first.
    // Epic+Rare Group = 100% - Legendary
    // Rare = Epic+Rare Group - Epic
    const legendaryOdds = baseLegendary+((1-baseLegendary)*(pulledSinceLegendary/capLegendary));
    const epicOdds = (1 - legendaryOdds) * (baseEpic+((1-baseEpic)*(pulledSinceEpic/capEpic)));
    const rareOdds = (1 - (legendaryOdds+epicOdds));

    setBoxLegendarySize(legendaryOdds*boxSize);
    setBoxEpicSize(epicOdds*boxSize);
    setBoxRareSize(rareOdds*boxSize);
}

function RarityBoxes(props) {
    //const [ boxLegendarySize, setBoxLegendarySize ] = useState(boxSize*baseLegendary);
    //const [ boxEpicSize, setBoxEpicSize ] = useState(boxSize*baseEpic);
    //const [ boxRareSize, setBoxRareSize ] = useState(boxSize*(1-(baseLegendary+baseEpic)));
    const legendaryLastIndex = props.pulls.lastIndexOf('legendary')+1
    const epicLastIndex = props.pulls.lastIndexOf('epic')+1

    const pulledSinceLegendary = Math.max(Math.min(props.pulls.length-legendaryLastIndex,capLegendary),0)
    const pulledSinceEpic = Math.min(Math.max(Math.min(props.pulls.length-epicLastIndex,capEpic),0), pulledSinceLegendary)
    
    const legendaryOdds = baseLegendary+((1-baseLegendary)*(pulledSinceLegendary/capLegendary));
    const epicOdds = (1 - legendaryOdds) * (baseEpic+((1-baseEpic)*(pulledSinceEpic/capEpic)));
    const rareOdds = (1 - (legendaryOdds+epicOdds));

    return (
        <>
            <div className='nextPullText'>Next pull odds:</div>
            <div className='rarityContainer' style={{width:boxSize}}>
                <div className='rarityMeterCapLegendary'/>
                <div className='rarityMeterLegendary' style={{width:boxSize*legendaryOdds}}/>
                <div className='rarityMeterEpic' style={{width:boxSize*epicOdds}}/>
                <div className='rarityMeterRare' style={{width:boxSize*rareOdds}}/>
                <div className='rarityMeterCapRare'/>
            </div>
        </>
    )
}

export default RarityBoxes