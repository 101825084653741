import './App.css'

import StreamSource from './pages/streamSource/streamSource';
import Control from './pages/control/control'
import Home from './pages/home/home'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { useState } from 'react';

function App() {
  const [ pulls, setPulls ] = useState([])

  const queryParams = new URLSearchParams(window.location.search);
  const sessionId = queryParams.get('sessionId');

  /*const socket = socketio('http://localhost:8080', { query: {sessionId} })
  socket.on('connectionEstablished', data => {console.log(data)});
  socket.on('broadcast', data=>{
    console.log(data)
  })*/

  console.log(sessionId);
  return (
    <Router>
      <Switch>
        <Route path="/streamsource">
          <StreamSource pulls={pulls} setPulls={setPulls} sessionId={sessionId}/>
        </Route>
        <Route path="/control">
          <Control pulls={pulls} setPulls={setPulls} sessionId={sessionId}/>
        </Route>
        <Route path="/">
          <Home/>
        </Route>
      </Switch>
    </Router>
    
  );
}

export default App;
