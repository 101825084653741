import './pullItem.css'

import item_rare from '../../assets/guobastand_bluoba.png'
import item_epic from '../../assets/guobastand_puorba.png'
import item_legendary from '../../assets/guobastand_guolba.png'

const itemType = {
    'rare': item_rare,
    'epic': item_epic,
    'legendary': item_legendary
}

function PullItem(props) {
    return (
    <div className="pullItemBox">
        <img className="item" src={itemType[props.itemType]} alt={`${props.itemType} pull`}/>
    </div>
    )
}

export default PullItem;