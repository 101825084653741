import Display from '../../components/display/display'
import ControlPanel from '../../components/controlPanel/controlPanel'

import './control.css'

function Control(props) {
    return(
        <>
            <ControlPanel pulls={props.pulls} setPulls={props.setPulls} sessionId={props.sessionId}/>
            <hr/>
            <Display pulls={props.pulls}/>
        </>
        
    )
}

export default Control