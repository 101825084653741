import socketio from 'socket.io-client';
import Display from '../../components/display/display'

var connected = false;

function StreamSource(props) {
  if (!connected) {
    const socket = socketio(process.env.REACT_APP_INDEV ? `http://${window.location.hostname}:8080` : 'http:/guobapityapi.maufirf.com', { query: {sessionId: props.sessionId} })
    socket.on('connectionEstablished', data => {console.log(data)});
    socket.on('broadcast', data=>{
      console.log(data)
      props.setPulls(data)
    })
    connected = true;
  }
  return (
    <Display pulls={props.pulls}/>
  );
}

export default StreamSource;