import './display.css';

import PullItem from '../../components/pullItem/pullItem'
import RarityBoxes from '../rarityBoxes/rarityBoxes';
import { useEffect } from 'react';

function Display(props) {
    var displayedItems = props.pulls.map((rarity, i)=>{return <PullItem itemType={rarity} key={i}/>});
    useEffect(()=>{
        displayedItems=props.pulls.map((rarity, i)=>{return <PullItem itemType={rarity} key={i}/>})
    },[props.pulls])
    return (
        <div className="display">
            <div className="contentContainer">
                <h1>Wishes pulled: {props.pulls.length} pulls</h1>
                <div className="pullStats">
                    <p className="pullStatRare">
                        <b>Rare</b> pulls:
                        <b> {props.pulls.filter(pull=>pull==="rare").length}</b>
                    </p>
                    <p className="pullStatEpic">
                        <b>Epic</b> pulls:
                        <b> {props.pulls.filter(pull=>pull==="epic").length}</b>
                    </p>
                    <p className="pullStatLegendary">
                        <b>Legendary</b> pulls:
                        <b> {props.pulls.filter(pull=>pull==="legendary").length}</b>
                    </p>
                    <RarityBoxes pulls={props.pulls}/>
                </div>
                <div className="itemContainer">
                {displayedItems}
                </div>
            </div>
        </div>
    );
}

export default Display;